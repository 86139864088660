<template>
  <div class="box" style="background-color: #f8fcff;">
    <el-card style="width: 1200px;margin: 20px auto" class="box-card">
      <div slot="header" class="clearfix" style="padding: 0 0 50px 0;">
        <span style="font-size: 18px;">收银台</span>
      </div>
      <div class="banner" style="padding: 0 20px 0 20px;">
        <div>
          <img style="width: 64px;height: 64px;object-fit: cover;" src="/img/success_icon.png" alt="" />
        </div>
        <div style="margin: 0 0 0 20px;width: 92%;">
          <p style="font-size: 18px; overflow: hidden;width:100%;">
            <span style="float:left;"><span style="font-size: 18px;">订单提交成功，请尽快付款！</span><span
                style="font-size: 14px;">订单号：<span style="color: #C91C25; font-size: 14px;">{{
                  order.orderNo
                }}</span></span></span><span style="font-size: 14px;float:right;">订单金额：<span
                style="color: #C91C25;font-weight: 600;font-size: 18px;">￥{{ order.price }}</span></span>
          </p>
          <p style="font-size: 14px;margin-top: 20px;">
            请您在
            <span style="color: #C91C25;">15分钟</span>
            内完成支付，否则订单将会自动取消。
          </p>
        </div>
      </div>
      <p class="chose">选择支付方式</p>
      <div style="border-bottom: solid 1px #EEEEEE;" class="buy">
        <p style="margin-bottom: 30px;">
          <span @click="changePayMethod(0)" :class="payMethod == 0 ? 'paymetodselect' : 'paymetodnoselect'"
            style="position: relative;width: 220px;height: 68px;text-align: center;line-height: 68px;">
            <img style="width: 120px;height: 42px;display: inline-block; vertical-align: middle;" src="/img/zfb.png"
              alt="" />
            <img v-if="payMethod == 0" style="position: absolute;right: 0;bottom: 0;" src="/img/bluejiaobiao.png"
              alt="" />
          </span>
          <span @click="changePayMethod(1)" :class="payMethod == 1 ? 'paymetodselect' : 'paymetodnoselect'"
            style="position: relative;width: 220px;height: 68px;text-align: center;line-height: 68px;">
            <img style="width: 130px;height: 32px;display: inline-block; vertical-align: middle;" src="/img/vx.png"
              alt="" />
            <img v-if="payMethod == 1" style="position: absolute;right: 0;bottom: 0;" src="/img/bluejiaobiao.png"
              alt="" />
          </span>
        </p>
      </div>
      <div class="pay">
        <p>
          <el-button @click="jump"
            style="margin: 0 20px 20px 0;background-color: #C91C25;color: white;width: 160px;height: 44px;font-size: 18px;">
            立即购买
          </el-button>
        </p>
        <p>
          剩余付款时间：<span style="color: #C91C25;font-size: 14px;">{{ minute }}:{{ second }}</span>
        </p>
      </div>
      <div style="display:none;" v-html="form"></div>
    </el-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "fillInTheOrder",
  data() {
    return {
      active: 0,
      radio1: "1",
      orderId: 0,
      order: [],
      payMethod: 0,
      minutes: 15,
      seconds: 0,
      form: "",
    };
  },
  watch: {
    second: {
      handler(newVal) {
        this.num(newVal);
      },
    },
    minute: {
      handler(newVal) {
        this.num(newVal);
      },
    },
  },
  computed: {
    second: function () {
      return this.num(this.seconds);
    },
    minute: function () {
      return this.num(this.minutes);
    },
    ...mapState({
      get_GetOrderDetail: (state) => {
        return state.pay.get_GetOrderDetail;
      },
      get_UpdateOrderPayMethod: (state) => {
        return state.pay.get_UpdateOrderPayMethod;
      },
      get_OrderPay: (state) => {
        return state.pay.get_OrderPay;
      },
      get_UpdateOrderProductOrderStatus: (state) => {
        return state.pay.get_UpdateOrderProductOrderStatus;
      },
    }),
  },
  mounted() {
    this.orderId = this.$route.query.orderId;
    this.$store
      .dispatch("pay/GetOrderDetail", { id: this.orderId })
      .then(() => {
        this.order = this.get_GetOrderDetail;
        this.add();
      });
  },
  methods: {
    changePayMethod(obj) {
      this.payMethod = obj;
    },
    jump() {
      var that = this;
      that.$store
        .dispatch("pay/UpdateOrderPayMethod", {
          Id: that.orderId,
          PayMethod: that.payMethod,
        })
        .then(() => {
          if (that.get_UpdateOrderPayMethod.success) {
            if (that.payMethod == 1) {
              that.$router.push({ name: "wxPay", query: { id: that.orderId } });
            } else {
              that.$store
                .dispatch("pay/OrderPay", { id: that.orderId })
                .then(() => {
                  var form = that.get_OrderPay.data;
                  if (form) {
                    that.form = form;
                    setTimeout(function () {
                      that.payclick();
                    }, 500);
                  }
                });
            }
          }
        });
    },
    payclick() {
      document.forms["alipaysubmit"].submit();
    },
    num: function (n) {
      return n < 10 ? "0" + n : "" + n;
    },
    add() {
      var _this = this;
      var time = window.setInterval(function () {
        if (_this.seconds === 0 && _this.minutes !== 0) {
          _this.seconds = 59;
          _this.minutes -= 1;
        } else if (_this.minutes === 0 && _this.seconds === 0) {
          _this.seconds = 0;
          window.clearInterval(time);
          _this.$store
            .dispatch("pay/UpdateOrderProductOrderStatus", {
              Id: _this.orderId,
              ProductOrderStatus: 5,
            })
            .then(() => {
              if (_this.get_UpdateOrderProductOrderStatus.success) {
                _this.$router.push({
                  path: "/personalCenter/personalCenter",
                  query: {
                    value: 2,
                  },
                });
              }
            });
        } else {
          _this.seconds -= 1;
        }
      }, 1000);
    },
  },
};
</script>

<style lang="less" scoped>
.banner {
  display: flex;
}
.paymetodselect {
  border: 1px solid #24a2aa;
}
.paymetodnoselect {
  border: 1px solid #eeeeee;
}
.chose {
  height: 44px;
  background: #f4f4f4;
  border: 1px solid #eeeeee;
  font-size: 14px;
  line-height: 44px;
  padding: 0 0 0 20px;
  margin: 47px 0 30px 0;
}

.zf {
  img {
    width: 40px;
    height: 40px;
    margin-left: 76px;
  }
}

.buy {
  p {
    span {
      width: 120px;
      display: inline-block;
      height: 36px;
      // border: 1px solid #eeeeee;
      text-align: center;
      line-height: 36px;
      margin-right: 20px;
    }
  }
}

.pay {
  margin: 30px 0 60px 0;

  p {
    text-align: right;
  }
}

/deep/.el-radio__input.is-checked .el-radio__inner {
  border-color: #c91c25;
  background: #c91c25;
}

/deep/.el-radio.is-bordered.is-checked {
  border: solid 1px #eeeeee;
}

/deep/.el-radio__input.is-checked + .el-radio__label {
  color: #606266;
}
</style>
